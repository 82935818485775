@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css');

body {
  background: #f4f5ec;
  color: #142f53;
}
p, ul, label {
  color: #666;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
}
h1 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.form-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
}


/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px; 
  padding: 0px; 
  box-sizing: border-box;
}

body, html {
  height: 100%;
}



/*---------------------------------------------*/
input {
  outline: none;
  border: none;
  background-color: transparent;
}

textarea {
  outline: none;
  border: none;
  background-color: transparent;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}





/*//////////////////////////////////////////////////////////////////
[ Contact 2 ]*/
.bg-contact2 {
  width: 100%;  
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.container-contact2 {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(219,21,99,0.8);
  background: -webkit-linear-gradient(45deg, rgba(213,0,125,0.8), rgba(229,57,53,0.8));
  background: -o-linear-gradient(45deg, rgba(213,0,125,0.8), rgba(229,57,53,0.8));
  background: -moz-linear-gradient(45deg, rgba(213,0,125,0.8), rgba(229,57,53,0.8));
  background: linear-gradient(45deg, rgba(213,0,125,0.8), rgba(229,57,53,0.8));
}



/*------------------------------------------------------------------
[  ]*/

.contact2-form {
  width: 100%;
}

.contact2-form-title {
  display: block;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 90px;
}



/*------------------------------------------------------------------
[  ]*/

.wrap-input2 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-top: 37px;
}

.input2 {
  display: block;
  width: 100%;
  font-size: 15px;
  color: #555555;
  line-height: 1.2;
}

.focus-input2 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input2::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #2993ce;
  background: -webkit-linear-gradient(45deg, #2993ce, #4a629e);
  background: -o-linear-gradient(45deg, #2993ce, #4a629e);
  background: -moz-linear-gradient(45deg, #2993ce, #4a629e);
  background: linear-gradient(45deg, #2993ce, #4a629e);
}

.focus-input2::after {
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;

  font-size: 13px;
  color: #999999;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

/*---------------------------------------------*/
input.input2 {
  height: 45px;
}

input.input2 + .focus-input2::after {
  top: 16px;
  left: 0;
}

textarea.input2 {
  min-height: 115px;
  padding-top: 13px;
  padding-bottom: 13px;
}

textarea.input2 + .focus-input2::after {
  top: 16px;
  left: 0;
}

.input2:focus + .focus-input2::after {
  top: -6px;
  font-size: 10px;
}

.input2:focus + .focus-input2::before {
  width: 100%;
}

.has-val.input2 + .focus-input2::after {
  top: -6px;
  font-size: 10px;
}

.has-val.input2 + .focus-input2::before {
  width: 100%;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact2-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 37px;
}

.wrap-contact2-form-btn {
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  width: auto;
  overflow: hidden;
  margin: 0 auto;
}

.contact2-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #142f53;
  background: -webkit-linear-gradient(-135deg, #4a629e, #142f53, #4a629e, #142f53);
  background: -o-linear-gradient(-135deg, #4a629e, #142f53, #4a629e, #142f53);
  background: -moz-linear-gradient(-135deg, #4a629e, #142f53, #4a629e, #142f53);
  background: linear-gradient(-135deg, #4a629e, #142f53, #4a629e, #142f53);
  top: 0;
  left: -100%;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.contact2-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 244px;
  height: 50px;

 
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  

}

.wrap-contact2-form-btn:hover .contact2-form-bgbtn {
  left: 0;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-contact2 {
    padding: 72px 15px 90px 15px;
  }
}



/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: white;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f12a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

ul {
  padding-left: 40px;
}
.question {
  margin-bottom: 20px;
}
.question-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
}

#progress_bar {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background-color: #ccc;
}
.progress-bar-success {
  background-color: #5cb85c;
}
#page_navigation {
  position: fixed;
  bottom: 0;
}
@media (max-width: 768px) {
  #page_navigation {
    right: 10px;
  }
}

#confirm_popup .modal-body {
  background: #134886;
    color: #fff;
    padding: 80px 30px;
}
#confirm_popup button.close {
  background: #e63737;
    padding: 4px 12px 10px;
    border-radius: 50%;
    right: -20px;
    position: absolute;
    top: -20px;
    color: #fff;
    font-size: 30px;
    opacity: 1;
}
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
}
.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
 max-width:inherit; /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events:all;
}
#unfinished {
  text-align: left;
  margin-top: 20px;
}

.box-column {
  border: 1px solid #ccc;
  padding: 15px;
}
.box-score {
    border: 3px solid #ccc;
    text-align: center;
    font-weight: 700;
    max-width: 100px;
    height: 100px;
    padding: 15px;
    font-size: 40px;
    margin: auto;
}
label {
  cursor: pointer;
}
.box-column label {
  display: block;
}
.box-column label input {
  margin-right: 5px;
}
.box {
  position: relative;
}
.box::before {
  width: 35px;
  height: 100%;
  position: absolute;
  right: 100%;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: center;
  background: #142f53;
  color: #fff;
  font-weight: 700;
  line-height: 2;
}
.box.Box_1::before {
  content: 'Box 1';
}
.box.Box_2::before {
  content: 'Box 2';
}
.box.Box_3::before {
  content: 'Box 3';
}
.box.Box_4::before {
  content: 'Box 4';
}
.box.Box_5::before {
  content: 'Box 5';
}
.box.Box_6::before {
  content: 'Box 6';
}
.box.Box_7::before {
  content: 'Box 7';
}
.box.Box_8::before {
  content: 'Box 8';
}
.box.Box_9::before {
  content: 'Box 9';
}
.box.Box_10::before {
  content: 'Box 10';
}
.box.Box_11::before {
  content: 'Box 11';
}
.box.Box_12::before {
  content: 'Box 12';
}
.box.Box_13::before {
  content: 'Box 13';
}
.box.Box_14::before {
  content: 'Box 14';
}
.box.Box_15::before {
  content: 'Box 15';
}
.box.Box_16::before {
  content: 'Box 16';
}

@media (max-width: 768px) {
  .box::before {
    left: 0;
    right: 0;
    bottom: 100%;
    width: 100%;
    height: 30px;
    writing-mode: initial;
  }
}